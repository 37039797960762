var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',[_vm._v(" fas fa-key ")]),_c('v-toolbar-title',{staticClass:"pl-4"},[_vm._v(" Novo Configurações de chaves - Cotação e Conferência: ")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"#018656"},on:{"click":function($event){(_vm.dialogAddToken = true, _vm.edit = false)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v(" Adicionar ")],1),_c('v-app-bar-nav-icon',{on:{"click":_vm.fechar}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"span":12}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":"","headers":_vm.grid.tokens.cabecalho,"loading":_vm.grid.tokens.carregando,"items":_vm.grid.tokens.items,"items-per-page":-1,"height":"calc(100vh - 172px)","fixed-header":""},scopedSlots:_vm._u([{key:"item.tipo_token",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo_token == 2 ? "Conferência" : "Cotação")+" ")]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [(item.tipo === 0 || item.tipo === 1 && item.tipo_token !== 2)?_c('span',[(item.tipo === 1)?_c('v-icon',{attrs:{"small":"","color":"#018454"}},[_vm._v(" fas fa-check-circle ")]):(item.tipo === 0)?_c('v-icon',{attrs:{"small":"","color":"#FF0000"}},[_vm._v(" fas fa-times-circle ")]):_vm._e()],1):_c('span',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%"}},[_vm._v(" Não se aplica ")])]}},{key:"item.canal_vendas",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.canal_vendas),function(canal){return _c('span',[_vm._v(" "+_vm._s(canal.idcanal_vendas == item.idcanal_vendas ? canal.nome : "")+" ")])})}},{key:"item.troca_devolucao",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v(" "+_vm._s(item.troca_devolucao ? "Sim" : "Não")+" ")])]}},{key:"item.ignorar_integracao",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v(" "+_vm._s(item.ignorar_integracao ? "Sim" : "Não")+" ")])]}},{key:"item.operacoes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","dense":"","small":""},on:{"click":function($event){(_vm.dialogAddToken = true, _vm.edit = true, _vm.objeto = item)}}},on),[_c('v-icon',{attrs:{"small":"","dense":"","color":"primary"}},[_vm._v("fas fa-pen")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","dense":""},on:{"click":function($event){return _vm.exclusao(item)}}},on),[_c('v-icon',{attrs:{"small":"","dense":"","color":"error"}},[_vm._v("fas fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}])})],1)],1)],1)],1),_c('dialog-add-edit',{attrs:{"dialog":_vm.dialogAddToken,"edit":_vm.edit,"objeto":_vm.objeto},on:{"close":function($event){_vm.dialogAddToken = false},"atualizar":function($event){return _vm.carregarTokens()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }