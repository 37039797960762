<template>
  <div>
    <!-- Início principal -->
    <v-card flat>
      <v-toolbar flat>
        <v-icon> fas fa-key </v-icon>
        <v-toolbar-title class="pl-4">
          Novo Configurações de chaves - Cotação e Conferência:
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn style="color: white;" color="#018656" @click="(dialogAddToken = true, edit = false)">
          <v-icon class="mr-2">fas fa-plus</v-icon>
          Adicionar
        </v-btn>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>

      <v-card-text>
        <v-row>
          <v-col :span="12">
            <v-data-table dense hide-default-footer :headers="grid.tokens.cabecalho" :loading="grid.tokens.carregando"
            :items="grid.tokens.items" class="elevation-1" :items-per-page="-1" height="calc(100vh - 172px)" fixed-header>

              <template #item.tipo_token="{ item }">
                {{ item.tipo_token == 2 ? "Conferência" : "Cotação" }}
              </template>

              <template #item.tipo="{ item }">
                <span v-if="item.tipo === 0 || item.tipo === 1 && item.tipo_token !== 2">
                  <v-icon v-if="item.tipo === 1" small color="#018454">
                    fas fa-check-circle
                  </v-icon>
                  <v-icon v-else-if="item.tipo === 0" small color="#FF0000">
                    fas fa-times-circle
                  </v-icon>
                </span>
                <span v-else class="d-flex justify-center align-center" style="width: 100%">
                  Não se aplica
                </span>
              </template>

              <template #item.canal_vendas="{ item }">
                <span v-for="canal in canal_vendas">
                  {{ canal.idcanal_vendas == item.idcanal_vendas ? canal.nome : "" }}
                </span>
              </template>

              <template #item.troca_devolucao="{ item }">
                <div class="d-flex justify-center align-center">
                  {{ item.troca_devolucao ? "Sim" : "Não" }}
                </div>
              </template>

              <template #item.ignorar_integracao="{ item }">
                <div class="d-flex justify-center align-center">
                  {{ item.ignorar_integracao ? "Sim" : "Não" }}
                </div>
              </template>

              <template #item.operacoes="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon dense small class="mr-1" @click="(dialogAddToken = true, edit = true, objeto = item)">
                      <v-icon small dense color="primary">fas fa-pen</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon small dense @click="exclusao(item)">
                      <v-icon small dense color="error">fas fa-trash</v-icon>
                    </v-btn>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dialog-add-edit :dialog="dialogAddToken" :edit="edit" :objeto="objeto" @close="dialogAddToken = false"
    @atualizar="carregarTokens()" />
  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import DialogAddEdit from '../components/chavesCotacao/form.vue'

export default {
  components: {
    DialogAddEdit
  },
  data() {
    return {
      dialogAddToken: false,
      edit: false,
      canal_vendas: [],
      objeto: {},
      grid: {
        tokens: {
          cabecalho: [
            { text: "Nome", value: "nome_original", sortable: false },
            { text: "Token", value: "valor", sortable: false },
            { text: "Tipo", value: "tipo_token", sortable: false },
            { text: "Canal de Vendas", value: "canal_vendas", sortable: false },
            { text: "Troca/Devolução", value: "troca_devolucao", sortable: false, align: 'center' },
            { text: "Embalamento Manual", value: "tipo", align: "center", sortable: false },
            { text: "Ignorar Integração", value: "ignorar_integracao", align: "center", sortable: false, align: 'center' },
            { text: "Ações", value: "operacoes", align: "end", sortable: false, align: 'center' },
          ],
          items: [],
          carregando: false
        }
      }
    }
  },

  methods: {
    async carregarTokens() {
      this.grid.tokens.carregando = true;
      this.grid.tokens.items = [];
      let url = `${baseApiUrl}/token?por_pagina=0`

      await axios.get(url)
        .then((res) => {
          this.grid.tokens.items = res.data.dados;
        }).catch((err) => {
          console.log(err)
          showError("Não foi possível carregar as chaves")
        }).finally(() => {this.grid.tokens.carregando = false})
    },

    async carregarCanais() {
      let url = `${baseApiUrl}/canal-venda`

      await axios.get(url)
        .then((res) => {
          this.canal_vendas = res.data.dados;
        }).catch((err) => {
          console.log(err)
        })
    },

    exclusao(chave) {
      this.$swal({
        title: 'Confirmação',
        text: `Tem certeza que deseja realizar a exclusão da chave ${chave.nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async(res) => {
        if(res.isConfirmed) {
          let url = `${baseApiUrl}/token/${chave.codigo}`;

          await axios.delete(url)
            .then((result) => {
              showSuccess("Chave deletada com sucesso!")
              this.carregarTokens()
            }).catch((err) => {
              console.log(err);
              showError("Não foi possível realizar a exclusão da chave")
            })
        }
      });
    },

    fechar() {
      this.$router.push("/");
    },
  },

  async mounted() {
    await this.carregarTokens()
    await this.carregarCanais()
  }
}
</script>